<template>
  <div class="w-sm-100 w-xs-100">
    <vs-row class="lg-trading-content" v-if="sellCryptoExchangeRate">

<!--      &lt;!&ndash;Only show for screen width > 767px&ndash;&gt;-->
<!--      <vs-row vs-type="flex" vs-w="12" vs-justify="center" class="white-card-md mb-2 hidden-xs" v-if="cryptoHoldings && cryptoHoldings.bal_amt > 0">-->
<!--        <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="center" v-if="sellCryptocurrency">-->
<!--          <cryptoicon :symbol="sellCryptocurrency" size="30" class="m-1 pl-1"/>-->
<!--          <div class="text-medium">-->
<!--            <div>-->
<!--              Holding-->
<!--              <span class="trading-text-black">{{ sellCryptocurrency.toUpperCase() }}-->
<!--              <span v-if="sellCryptocurrency.toUpperCase() !== 'USDT'">-->
<!--                {{ formatCryptoPrice(cryptoHoldings.bal_amt) }}-->
<!--              </span>-->
<!--              <span v-else>-->
<!--                {{ formatFiatPrice(cryptoHoldings.bal_amt) }}-->
<!--              </span>-->
<!--              </span>-->
<!--              <span class="trading-text-black">({{ sellFiatCurrency.toUpperCase() }} {{ formatFiatPrice(cryptoHoldings.port_val) }})</span></div>-->
<!--          </div>-->
<!--        </vs-col>-->
<!--&lt;!&ndash;        <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end" vs-align="center">&ndash;&gt;-->
<!--&lt;!&ndash;          &lt;!&ndash;          <div class="text-success">43%</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;        </vs-col>&ndash;&gt;-->
<!--      </vs-row>-->

<!--      &lt;!&ndash;Only show for screen width > 767px&ndash;&gt;-->
<!--      <vs-row vs-type="flex" vs-w="12" vs-justify="center" class="white-card-xs-highlighted mb-2 hidden-xs">-->

<!--        <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start" vs-align="center"-->
<!--                class="trading-border-right-green p-1">-->
<!--          <img src="/assets/trading-assets/ajax-loader-green.gif" alt="loader gif" class="sm-logo ml-2 mr-2"/>-->
<!--          <div class="text-medium">Price is valid for {{ (exchangeRateIntervalCountdown % 60).toString() }} seconds</div>-->
<!--        </vs-col>-->

<!--        <vs-col vs-w="6" vs-type="flex" vs-justify="flex-end" vs-align="center" class="p-1">-->
<!--&lt;!&ndash;          <div class="text-medium">Price :</div>&ndash;&gt;-->
<!--          <div class="ml-2 mr-2 text-large fw-700 trading-text-black">1 {{sellCryptocurrency.toUpperCase()}} = {{sellFiatCurrency.toUpperCase()}} {{formatFiatPrice(sellCryptoExchangeRate)}}</div>-->
<!--        </vs-col>-->

<!--      </vs-row>-->

<!--      &lt;!&ndash;Only show for screen width <= 767px&ndash;&gt;-->
<!--      <vs-row vs-type="flex" vs-w="12" vs-justify="space-between" vs-align="center"-->
<!--              class="white-card-md-highlighted hidden-md hidden-sm hidden-lg mb-2 p-1">-->
<!--        <div class="fw-600">-->
<!--          Price-->
<!--        </div>-->
<!--        <div class="fw-700 trading-text-black">-->
<!--          1 {{sellCryptocurrency.toUpperCase()}} = {{sellFiatCurrency.toUpperCase()}} {{formatFiatPrice(sellCryptoExchangeRate)}}-->
<!--        </div>-->
<!--      </vs-row>-->

<!--      &lt;!&ndash;Only show for screen width <= 767px&ndash;&gt;-->
<!--      <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center" class="hidden-md hidden-sm hidden-lg mb-2">-->

<!--        <img src="/assets/trading-assets/ajax-loader-green.gif" alt="loader gif" class="sm-logo ml-2 mr-2"/>-->
<!--        <div class="fw-600">Price is valid for {{(exchangeRateIntervalCountdown % 60).toString()}} s</div>-->

<!--      </vs-row>-->


      <!--NEW PRICE DESIGN AND TIMER FOR DESKTOP & MOBILE-->

      <!--Only show for screen width <= 767px-->
      <vs-row vs-type="flex" vs-w="12" vs-justify="center" vs-align="center"
              class="white-card-md-highlighted mb-2 p-1">
        <div class="trading-flex-column text-center">
          <div class="fw-700 trading-text-black">
            1 {{ sellCryptocurrency.toUpperCase() }} = {{ sellFiatCurrency.toUpperCase() }}
            {{ formatFiatPrice(sellCryptoExchangeRate) }}
          </div>
<!--          <div class="fw-700 trading-text-black">-->
<!--            1 {{ sellCryptocurrency.toUpperCase() }} = {{ sellFiatCurrency.toUpperCase() }}-->
<!--            59,000-->
<!--          </div>-->
          <div class="trading-flex-center">
            <img src="/assets/trading-assets/ajax-loader-green.gif" alt="loader gif" class="sm-logo"/>
            <div class="fw-600 ml-1 text-medium">valid for {{ (exchangeRateIntervalCountdown % 60).toString() }} s</div>
          </div>
        </div>

      </vs-row>

      <vs-row vs-type="flex" vs-w="12" vs-justify="center" class="white-card-lg">
        <div class="trading-flex-column w-100 text-center">

          <div class="p-2 mb-1" v-if="cryptoHoldings">
            <div class="text-medium">Holding</div>
            <div class="trading-flex-center">
              <h2 class="trading-text-black pointer" @click="populateSellAmount()">{{ sellFiatCurrency.toUpperCase() }} {{ formatFiatPrice(fiatHoldingValue) }}</h2>
<!--              <h2 class="trading-text-black pointer" @click="populateSellAmount()">SGD 98.33</h2>-->
            </div>
            <div class="pointer text-large trading-flex-center">
              <cryptoicon :symbol="sellCryptocurrency" size="20" class="mr-1" style="margin-bottom: 2px"/>
              <div class="trading-text-darkgrey">{{sellCryptocurrency.toUpperCase()}}
                <span v-if="sellCryptocurrency.toUpperCase() !=='USDT'">{{ formatCryptoPrice(cryptoHoldings.bal_amt)}}</span>
                <span v-else>{{ formatFiatPrice(cryptoHoldings.bal_amt)}}</span>
              </div>
<!--              <div class="trading-text-darkgrey">-->
<!--                <span v-if="sellCryptocurrency.toUpperCase() !=='USDT'">BTC 0.00166667</span>-->
<!--&lt;!&ndash;                <span v-else>{{ formatFiatPrice(cryptoHoldings.bal_amt)}}</span>&ndash;&gt;-->
<!--              </div>-->
            </div>
          </div>

          <div class="trading-border-top-solid">
            <div class="mt-2 mb-1 text-xlarge">
              Amount to sell
            </div>
            <div class="text-success text-medium fw-700">
              {{sellFiatCurrency.toUpperCase()}}
            </div>
            <div class="mt-1">
              <input type="text" inputmode="decimal" autocomplete="off" class="trading-input-amount-md trading-text-black" v-model="sellAmount" placeholder="0" @input="inputSellAmount" @keyup="keyPressFunction" />
            </div>
<!--            <div class="p-2 mb-3">-->
<!--              <div class="text-medium mb-1 mt-1">Worth</div>-->
<!--              <div class="fw-700 trading-flex-center text-xlarge">-->
<!--              <cryptoicon :symbol="sellCryptocurrency" size="26" class="mr-1" style=""/>-->
<!--              <div class="mr-1 trading-text-black">{{sellCryptocurrency.toUpperCase()}} {{formatCryptoPrice(cryptoAmountEquivalent)}}</div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="text-small text-error mb-2" v-if="isMinimumSellAmount">
              Minimum amount to sell : SGD > 0
            </div>

            <div class="mb-5">
              <div class="trading-flex-center text-medium">
                Worth
                <div class="ml-1 trading-text-black" v-if="isNaN(cryptoAmountEquivalent) || cryptoAmountEquivalent < 0">{{sellCryptocurrency.toUpperCase()}} {{formatCryptoPrice(0)}}</div>
                <div class="ml-1 trading-text-black" v-else>{{sellCryptocurrency.toUpperCase()}} {{formatCryptoPrice(cryptoAmountEquivalent)}}</div>
<!--                <div class="ml-1 trading-text-black">BTC 0.00166667</div>-->
              </div>
            </div>

            <!--Fee Summary-->
            <!--Fee Summary-->
<!--            <div class="trading-flex-center">-->
<!--              <vs-row vs-w="6" vs-type="flex" vs-justify="space-between" vs-align="center" class="p-1" style="border-top:1px solid lightgrey">-->
<!--                <div class="text-medium text-left">Fee (0%)</div>-->
<!--                <div class="text-medium ">SGD 0.00</div>-->
<!--              </vs-row>-->
<!--            </div>-->


            <!--Amount to pay Summary-->
<!--            <div class="trading-flex-center">-->
<!--              <vs-row vs-w="6" vs-type="flex" vs-justify="space-between" vs-align="center" class="p-1" style="border-top:1px solid lightgrey;border-bottom: 1px solid lightgrey" v-if="windowInnerWidth > 635">-->
<!--                <div class="text-medium text-left">You will receive</div>-->
<!--                <div class="text-medium">SGD {{sellAmount}}</div>-->
<!--              </vs-row>-->
<!--              <vs-row vs-w="11" vs-type="flex" vs-justify="space-between" vs-align="center" class="p-1" style="border-top:1px solid lightgrey;border-bottom: 1px solid lightgrey" v-else>-->
<!--                <div class="text-medium text-left">You will receive</div>-->
<!--                <div class="text-medium">SGD {{sellAmount}}</div>-->
<!--              </vs-row>-->
<!--            </div>-->

          <div class="mt-3 p-2 vs-con-loading__container" id="button-container">
              <button class="btn-trading-negative w-50 pointer"
                      @click="clickToSell()"
                      :disabled="isButtonDisabled || isNaN(cryptoAmountEquivalent) ||cryptoAmountEquivalent <= 0 || sellAmount < 0"
                      :class="{'btn-disabled' :  isButtonDisabled || isNaN(cryptoAmountEquivalent) || cryptoAmountEquivalent <= 0 || sellAmount < 0} ">
                Sell {{sellCryptocurrency.toUpperCase()}}</button>
            </div>

            <!--PROCESSING MESSAGE-->
            <div v-if="isProcessing">
              Processing ....
            </div>

          <!--ERROR MESSAGE-->
            <div class="p-2">
              <div v-if="errorInSellCrypto" class="text-error">
                There is an error in selling crypto. Please refresh the page and try again.
              </div>
            </div>
          </div>
        </div>
      </vs-row>

    </vs-row>
    <vs-row class="lg-trading-content" v-else>


     <div class="text-center" style="margin:0 auto">
       <div class="text-xlarge mb-3">
         Price is loading ..........
       </div>
       <div class="text-medium">
         Note : If it is taking too long, please try refresh the page again.
       </div>
     </div>


    </vs-row>
  </div>
</template>
<script>



import {debounce} from "../../helpers/helpers";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'trading-sell-crypto',
  components: {

  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      sellAmount: null,
      sellFiatCurrency : "",
      sellCryptocurrency : "",
      fiatAmountEquivalent: 0,
      cryptoAmountEquivalent : 0,
      sellCryptoExchangeRate: 0,
      lockedKeyExchangeRate : 0,
      fiatHoldingValue : 0,

      exchangeRateInterval: null,
      exchangeRateIntervalCountdown: 60,

      sellRedirectUrl: null,

      isButtonDisabled : false,

      errorInSellCrypto : false,

      isProcessing : false,
      windowInnerWidth : false,

      debugConsole : false,

      isMinimumSellAmount : false

    }
  },
  watch: {
    $route() {

    },
    sellAmount: debounce(function (newVal, oldVal) {
      if (Number(newVal) !== Number(oldVal)) {
        // console.log("old val not equal to new val");
        this.inputSellAmount();
      }
    }, 800),
    exchangeRateIntervalCountdown : function(newVal,oldVal)
    {
      // console.log("Interval countdown newVal in sell page", newVal);
      // console.log("Interval countdown oldVal in sell page", oldVal);

      if(newVal < 3)
      {
        this.isButtonDisabled = true;

        //load the spinner
        this.$vs.loading({
          container: '#button-container',
          scale: 0.5,
          color: 'success'
        })
      }

      if (newVal < 0)
      {
        this.exchangeRateIntervalCountdown = 60

        let data = {};
        data.fiat_ccy = this.sellFiatCurrency.toUpperCase();
        data.crypto_ccy =  this.sellCryptocurrency.toUpperCase();

        this.callExchangeRateAction(data).then(res => {
         if(this.debugConsole) console.log("get sell exc rate after 60s", res.data);

          this.lockedKeyExchangeRate = res.data.lock_key;
          if(this.debugConsole) console.log("New Locked key Sell exc rate", this.lockedKeyExchangeRate);

          this.sellCryptoExchangeRate = res.data && res.data.sell_rt;

          // this.fiatAmountEquivalent = this.formatFiatPrice(Number(this.sellAmount) * this.sellCryptoExchangeRate);

          //fiat holding value is now based on how many bitcoin user is holding * selling exc rate from the locked rate
          this.fiatHoldingValue = this.formatCryptoPrice(this.cryptoHoldings.bal_amt) * this.sellCryptoExchangeRate;
          if(this.debugConsole) console.log("This fiat Holding Value with new exc rate", this.fiatHoldingValue);

          //check the percentage of the sell amount against the fiat holding value
          let percentage = (this.sellAmount/this.fiatHoldingValue) * 100;
          if(this.debugConsole) console.log('Percentage after update of sell exc rate', percentage);

          //use the percentage to calculate the value worth in crypto. This is the value sent to the backend
          this.cryptoAmountEquivalent = (this.formatCryptoPrice(this.cryptoHoldings.bal_amt) * percentage )/100;
          if(this.debugConsole) console.log("Crypto Amount Equivalent after update, based on percentage", this.cryptoAmountEquivalent);


          if(!this.sellAmount || this.sellAmount.length <= 0 || Number(this.sellAmount) < 0 || percentage > 100)
          {
            this.isButtonDisabled = true;
          }else
          {
            this.isButtonDisabled = false;
          }

          //for showing the minimum amount to sell text
          if(!this.sellAmount || this.sellAmount.length <= 0 || Number(this.sellAmount) < 0)
          {
            this.isMinimumSellAmount = true;
          }else
          {
            this.isMinimumSellAmount = false;
          }


          //remove the spinner
          this.$vs.loading.close('#button-container > .con-vs-loading')

          //call the balances again so the crypto portfolio value is updated with the correct value, which is
          //based on the exchange rate.
          this.getUserBalances().then(res => {
            if(this.debugConsole)  console.log("Get user balances in Sell page", res.data);
          }).catch(err => {
            console.log("Error in getting user balances", err.response);
          })




        }).catch(err => {
          console.log("Error in calling exchange rate action in sell after 60s", err.response)
        })
      }
    }
  },
  computed: {
    ...mapGetters(["tradingBuyInfoGetter","userDetailsGetter","userFiatBalanceGetter", "userCryptoBalanceGetter"]),

    cryptoHoldings()
    {
      let cryptoHoldings = this.userCryptoBalanceGetter && this.userCryptoBalanceGetter.find(item => item.ccy === this.sellCryptocurrency.toUpperCase())
      return cryptoHoldings;
    },
    fiatBalance(){
      let fiatBalance = this.userFiatBalanceGetter && this.userFiatBalanceGetter.find(item => item.bal_type === 'fiat')
      return Number(fiatBalance.bal_amt);
    },



  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  mounted() {
    this.windowInnerWidth = window.innerWidth;

    if(this.debugConsole) console.log("User Fiat Balance in sell page", this.userFiatBalanceGetter);
    if(this.debugConsole) console.log("user crypto balance in sell page", this.userCryptoBalanceGetter);

    if(this.debugConsole)  console.log("params on selling page", this.$route.params);


    this.exchangeRateInterval = setInterval(function () {
      this.exchangeRateIntervalCountdown -= 1;

    }.bind(this), 1000);



    this.sellAmount = null;
    this.sellFiatCurrency = 'sgd';
    this.sellCryptocurrency = this.$route.params.cryptocurrency || 'btc';



    //TODO get the exc rate of this crypto from the backend API - need to know how to call it every 58 seconds
    // this.sellCryptoExchangeRate = 30000.00;

    let data = {};
    data.fiat_ccy = this.sellFiatCurrency.toUpperCase();
    data.crypto_ccy = this.sellCryptocurrency.toUpperCase();

    this.callExchangeRateAction(data).then(res => {
      if(this.debugConsole)  console.log("get sell exc rate when mounted", res.data);

      this.lockedKeyExchangeRate = res.data.lock_key;
      if(this.debugConsole)  console.log("Lock key exc rate when mounted", this.lockedKeyExchangeRate);

      this.sellCryptoExchangeRate = res.data && res.data.sell_rt;
      if(this.debugConsole) console.log("Sell Crypto Exc rate when mounted", this.sellCryptoExchangeRate);

      // this.fiatAmountEquivalent = this.formatFiatPrice(Number(this.sellAmount) * this.sellCryptoExchangeRate);
      // console.log("Fiat Amount Equivalent", this.fiatAmountEquivalent);

      if(this.debugConsole) console.log("This cryptoholdings bal amt", this.cryptoHoldings.bal_amt);

      //fiat holding value is now based on how many bitcoin user is holding * selling exc rate from the locked rate
      this.fiatHoldingValue = this.formatCryptoPrice(this.cryptoHoldings.bal_amt) * this.sellCryptoExchangeRate;
      if(this.debugConsole)  console.log("This fiat Holding Value", this.fiatHoldingValue);


    }).catch(err => {
      console.log("Error in calling exchange rate action in sell", err.response)
    })

    //check if the sellAmount is less than 1 or not. if it is less than 1, disabled the button
    // this.isButtonDisabled = this.sellAmount < 1 || this.sellAmount === null ? true : false;
    // if(this.debugConsole) console.log("this sell button disabled", this.isButtonDisabled);

    if(this.sellAmount < 1 || this.sellAmount === null)
    {
      this.isButtonDisabled = true;
      this.isMinimumSellAmount = true;
    }else
    {
      this.isButtonDisabled = false;
      this.isMinimumSellAmount = false;
    }




  },
  created() {
    window.addEventListener("resize", () => {this.windowInnerWidthHandler()});
  },
  beforeDestroy() {
    // console.log("beforeDestroy() fn to destroy the exc rate interval");
    window.removeEventListener("resize", () => {this.windowInnerWidthHandler()} );
    clearInterval(this.exchangeRateInterval);
  },
  destroyed() {
    // console.log("Destroy() the exc rate interval");
    clearInterval(this.exchangeRateInterval)
  },
  methods: {
    ...mapActions(["sellCryptoAction","callExchangeRateAction","getUserBalances"]),

    windowInnerWidthHandler() {
      this.windowInnerWidth = window.innerWidth;

    },

    clickToSell(){
      this.isProcessing = true;
      setTimeout(()=>{
        this.sellCrypto();
      },1000);
    },

    inputSellAmount(e) {
      if(this.debugConsole)  console.log('Sell amount', this.sellAmount);
      // if(this.debugConsole) console.log("Event value before", e.target.value);
      //
      // //Allow user to type to only 2 decimals points
      // var t = e.target.value;
      // e.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
      // this.sellAmount = e.target.value;
      //
      // if(this.debugConsole) console.log("Event value after", e.target.value);
      // if(this.debugConsole) console.log("Sell amount after", this.sellAmount);

      //check the percentage of the sell amount against the fiat holdingvalue
      if(this.debugConsole) console.log("Fiat Holding Value when inserting sell amount", this.fiatHoldingValue);

      let percentage = (this.sellAmount/this.fiatHoldingValue) * 100;
      if(this.debugConsole) console.log('Percentage', percentage);


      if(!this.sellAmount || this.sellAmount.length <= 0 || Number(this.sellAmount) < 0 || percentage > 100){
        this.isButtonDisabled = true;
      }
      else
      {
        this.isButtonDisabled = false;
      }

      //for displaying the minimum amount to sell text
      if(!this.sellAmount || this.sellAmount.length <= 0 || Number(this.sellAmount) < 0 ){
        this.isMinimumSellAmount = true;
      }
      else
      {
        this.isMinimumSellAmount = false;
      }

      //use the percentage to calculate the value worth in crypto. This is the value sent to the backend
      this.cryptoAmountEquivalent = (this.formatCryptoPrice(this.cryptoHoldings.bal_amt) * percentage )/100;
      if(this.debugConsole)  console.log("Crypto Amount Equivalent, based on percentage", this.cryptoAmountEquivalent);

      if(this.debugConsole)   console.log("is crypto amt equivalent", isNaN(this.cryptoAmountEquivalent));



      // this.$vs.loading({
      //   container: '#trading-buy-form',
      //   scale: 0.8,
      //   color: 'success'
      // })

      // this.fiatAmountEquivalent = this.formatFiatPrice(Number(this.sellAmount) * this.sellCryptoExchangeRate);
      // console.log("This FiatAmount Equivalent", this.fiatAmountEquivalent);

      // this.cryptoAmountEquivalent = this.formatCryptoPrice(Number(this.sellAmount) / this.sellCryptoExchangeRate);
      // console.log("This cryptoamount equivalent", this.cryptoAmountEquivalent);


    },

    sellCrypto() {
      let payload = {};
      payload.beg_ccy = this.sellCryptocurrency.toUpperCase();
      payload.end_ccy = this.sellFiatCurrency.toUpperCase();
      payload.beg_amt = this.formatCryptoPrice(this.cryptoAmountEquivalent);
      payload.lock_key = this.lockedKeyExchangeRate;

      if(this.debugConsole)   console.log("Payload for sell crypto", payload);
      this.sellCryptoAction(payload).then(res => {
        if(this.debugConsole) console.log("Sell crypto Action result", res.data);
        this.isProcessing = false;

        this.errorInSellCrypto = false;

        this.sellRedirectUrl = res.data.rdr_url;

        window.top.location.href = res.data.rdr_url;


      }).catch(err => {
        console.log("Error in sell crypto", err.response);
        this.errorInSellCrypto = true;
        this.isProcessing = false;
      })
    },

    populateSellAmount()
    {
      this.sellAmount = this.fiatHoldingValue;
      // this.sellAmount = this.sellCryptocurrency === 'USDT' ? this.formatFiatPrice(this.cryptoHoldings.bal_amt) : this.cryptoHoldings.bal_amt;
    },

    keyPressFunction(e){
      if(this.debugConsole)  console.log("keypress activated in sell page");

      //Allow user to type to only 2 decimals points
      this.sellAmount = (this.sellAmount.indexOf(".") >= 0) ? (this.sellAmount.substr(0, this.sellAmount.indexOf(".")) + this.sellAmount.substr(this.sellAmount.indexOf("."), 3)) : this.sellAmount ;

    },
  }
}

</script>
<style>


</style>